import React, { useState, useEffect } from "react"
import Layout from '../../components/layout';
import Seo from "../../components/seo";

const Index = (props) => {
  // variables
  const [data, setData] = useState();

  // console.log(props)
  const canonicalUrl = `/quiz`;
  const seo = {};

  // get data
  useEffect(() => {
    fetch(`https://api.trustyip.com/quiz-questions`)
      .then((response) => response.json())
      .then((actualData) => {
        setData(actualData);
        localStorage?.setItem('questions', JSON.stringify(actualData.data));
      });
  }, []);

  return (
    <Layout>
      <Seo title={`Quiz `} canonical={canonicalUrl} seo={seo} />
      <section className="my-5 py-5 bg-white">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12">
              <h1 className="mb-md-1 mb-1">We make protecting your<br />intellectual property simple</h1>
              <h3 className="my-4" style={{ fontSize: 25 + 'px', fontWeight: 300 }}>Let's see what type of IP protection best fits you</h3>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div class="col-md-3">
              <div class="d-grid gap-2">
                <a href="/quiz/intro" class="btn btn-warning btn-lg py-3">Take The IP Quiz</a>
              </div>
            </div>
            <div class="col-md-3">
              <div class="d-grid gap-2">
                <a href="/contact" class="btn btn-outline-primary btn-lg py-3" >Speak With An Attorney</a>
              </div>
            </div>
          </div>
        </div>
      </section>


    </Layout>
  )
}

export default Index;
